import { Typography, Container, Box } from "@mui/material";
import { Link } from "gatsby";

import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header";
const TermsAndConditions = () => {
  return (
    <>
      <div id='google_translate_element'></div>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <Container maxWidth='lg'>
        <Header isTransparent />

        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          Terms and Conditions
        </Typography>
        <Typography variant='body1' mb={2}>
          Yella Insurance broking Pvt. Limited (hereinafter referred to as
          Yella) operates the website www.yellainsure.com (hereinafter referred
          to as Yella). Yella is a licensed Insurance Broking Company holding a
          broking license from the Indian Insurance Regulator – Insurance
          Regulatory and Development Authority of India bearing License No:
          IRDA/DB, valid till 25/04/2026. Through Yella, Yella provides users
          with information and tools to make it easy to compare and buy
          insurance policies.
        </Typography>
        <Typography variant='body1' mb={2}>
          By visiting Yella and accessing the information, resources, services,
          products, and tools we provide, you understand and agree to accept and
          adhere to the following terms of use as stated in this policy
          (hereafter referred to as ‘ToU Agreement’), along with the terms and
          conditions as stated in our Privacy Policy.
        </Typography>
        <Typography variant='body1' mb={2}>
          This ToU Agreement is effective from the time you visit our website.
          We reserve the right, at any time, at our sole discretion, to change
          or otherwise modify the ToUAgreement without prior notice, and your
          continued access or use of Yella signifies your acceptance of the
          updated or modified ToU Agreement.
        </Typography>
        <Typography variant='h1' fontSize={"28px"} fontWeight='400' mb={2}>
          Using Yella
        </Typography>
        <Typography variant='body1' mb={2}>
          By visiting our website and accessing the information, resources,
          services, products, and tools we provide for you, either directly or
          indirectly (hereafter referred to as ‘Resources’), you agree to use
          these Resources only for the purposes intended as permitted by the
          terms of this ToU Agreement, and/or applicable laws, regulations and
          generally accepted online practices or guidelines.
        </Typography>
        <Typography variant='h1' fontSize={"28px"} fontWeight='400' mb={2}>
          Wherein, you understand that
        </Typography>
        <Box mb={2}>
          <ul>
            <li>
              To access our Resources, you may be required to provide certain
              information about yourself (such as name, address, contact
              details, car registration and purchase details, identity proof
              such as Aadhar ID, etc.). You agree that any information you
              provide will always be accurate, correct, and updated.
            </li>
            <li>
              By providing your information you explicitly authorize us to
              access on your behalf your records from the competent authority,
              such as Aadhar, RTO, etc. for the facilitation of your transaction
              with us.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of any
              login information associated with any account you use to access
              our Resources. Accordingly, you are responsible for all activities
              that occur under your account/s.
            </li>
            <li>
              Accessing (or attempting to access) any of our Resources by any
              means other than through the means we provide is strictly
              prohibited. You specifically agree not to access (or attempt to
              access) any of our Resources through any automated, unethical, or
              unconventional means.
            </li>
            <li>
              Engaging in any activity that disrupts or interferes with our
              Resources, including the servers and/or networks to which our
              Resources are located or connected, is strictly prohibited.
            </li>
            <li>
              Attempting to copy, duplicate, reproduce, sell, trade, or resell
              our Resources is strictly prohibited.
            </li>
            <li>
              You are solely responsible for any consequences, losses, or
              damages that we may directly or indirectly incur or suffer due to
              any unauthorized activities conducted by you, as explained above,
              and may incur criminal or civil liabilities.
            </li>
          </ul>
        </Box>
        <Typography variant='h1' fontSize={"28px"} fontWeight='400' mb={2}>
          User Generated Content
        </Typography>
        <Typography variant='body1' mb={2}>
          We may provide various open communication tools on our website, such
          as blog comments, blog posts, public chat, forums, message boards,
          newsgroups, product ratings and reviews, various social media
          services, etc. You understand that we do not pre-screen content posted
          by users of these various communication tools, which means that if you
          choose to use these tools to submit any type of content to our website
          (“user-generated content”), then it is your responsibility to use
          these tools responsibly and ethically. By posting information or
          otherwise using any open communication tools as mentioned, you agree
          that you will not upload, post, share or otherwise distribute any
          content that:
        </Typography>
        <Box mb={2}>
          <ul>
            <li>
              Is illegal, threatening, defamatory, abusive, harassing,
              degrading, intimidating, fraudulent, deceptive, invasive, racist,
              or contains any type of suggestive, inappropriate, or explicit
              language;
            </li>
            <li>
              Infringes on any trademark, patent, trade secret, copyright, or
              other proprietary rights of any party;
            </li>
            <li>
              Contains any type of unauthorized or unsolicited advertising;
            </li>
            <li>
              Impersonates any person or entity, including any Yella Insurance
              employees or representatives.
            </li>
          </ul>
        </Box>
        <Typography variant='body1' mb={2}>
          We do not assume any liability for any user-generated content posted
          by you or any other 3rd party users of our website. We have the right
          at our sole discretion to remove any content that, we feel in our
          judgment does not comply with this ToU Agreement, along with any
          content that we feel is otherwise offensive, harmful, objectionable,
          inaccurate, or violates any 3rd party copyrights or trademarks.
        </Typography>
        <Typography variant='body1' mb={2}>
          Any user-generated content posted by you using any open communication
          tools on our website, provided that it doesn’t violate or infringe on
          any 3rd party copyrights or trademarks, becomes the property of Yella
          Insurance, and as such, gives us a perpetual, irrevocable, worldwide,
          royalty-free, exclusive license to reproduce, modify, adapt,
          translate, publish, publicly display and/or distribute as we see fit.
          This only refers and applies to user-generated content as described.
          All user Personal Information, provided as part of our registration or
          purchase process, is covered by our{" "}
          <Link to='/privacy-policy'>Privacy Policy</Link>.
        </Typography>
        <Typography variant='h1' fontSize={"28px"} fontWeight='400' mb={2}>
          IPR and Copyright
        </Typography>
        <Typography variant='body1' mb={2}>
          All content and materials available on Yella, including but not
          limited to text, graphics, website name, code, images, and logos are
          either the intellectual property of Yella Insurance or under licensed
          use by Yella Insurance and are protected by applicable copyright and
          trademark law. Any inappropriate use, including but not limited to the
          reproduction, distribution, display or transmission of any content on
          this site is strictly prohibited unless specifically authorized by
          Yella Insurance.{" "}
        </Typography>
        <Typography variant='h1' fontSize={"28px"} fontWeight='400' mb={2}>
          Indemnification
        </Typography>
        <Typography variant='body1' mb={2}>
          You agree to indemnify and hold Yella Insurance, its parent company,
          officers, subsidiaries, affiliates, successors, assigns, directors,
          officers, agents, service providers, suppliers and employees, harmless
          from any claim or demand, including reasonable attorney fees and court
          costs, ade by any third party due to or arising out of content
          submitted by the user, users use of the service, violation of the ToU,
          breach by the user of any of the representations and warranties
          herein, or user’s violation of any rights of another.
        </Typography>
        <Typography variant='h1' fontSize={"28px"} fontWeight='400' mb={2}>
          Limitation of warranties
        </Typography>
        <Typography variant='body1' mb={2}>
          By using our website, you understand and agree that all Resources we
          provide are “as is” and “as available”. This means that we do not
          represent or warrant to you that:
        </Typography>
        <Box mb={2}>
          <ul>
            <li>
              The use of our Resources will meet your needs or requirements,
            </li>
            <li>
              The use of our Resources will be uninterrupted, timely, secure or
              free from errors,
            </li>
            <li>
              The information obtained by using our Resources will be accurate
              or reliable, and
            </li>
            <li>
              Any downloads from Yella will not harm your computer or device in
              any way.
            </li>
          </ul>
        </Box>
        <Typography variant='body1' mb={2}>
          To the maximum extent permitted by law, Yella Insurance has no
          liability about or arising out of the Website Information and
          recommendations. You are responsible for the final choice of your
          product. Please seek further advice from Yella Insurance or the
          relevant participating insurance companies before choosing your
          product, if you have any doubts or queries about the same.
          Furthermore, Yella Insurance does not guarantee when or if you will
          acquire the product that you have chosen and does not accept any
          liability arising out of delay in you acquiring the product you have
          chosen. You acknowledge and accept that the final issuance of the
          policy is subject to the underwriting norms and sole discretion of the
          insurance company whose policy you have chosen to buy.
        </Typography>
        <Typography variant='h1' fontSize={"28px"} fontWeight='400' mb={2}>
          Limitation of Liability
        </Typography>
        <Typography variant='body1' mb={2}>
          You expressly understand and agree that any claim against us shall be
          limited to the amount you paid if any. Yella will not be liable for
          any direct, indirect, incidental, consequential or exemplary loss or
          damages which may be incurred by you as a result of using our
          Resources.
        </Typography>
        <Typography variant='body1' mb={2}>
          UNLESS OTHERWISE EXPRESSED, YELLA INSURANCE EXPRESSLY DISCLAIMS ALL
          WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT.
        </Typography>
        <Typography variant='h1' fontSize={"28px"} fontWeight='400' mb={2}>
          Governing Law
        </Typography>
        <Typography variant='body1' mb={2}>
          This ToU Agreement and the Privacy Policy shall be governed and
          construed by the laws of the Republic of India and the courts at Kochi
          shall have exclusive jurisdiction on all matters and disputes arising
          out of and relating to the Site
        </Typography>
      </Container>
      <Box mt={4}>
        <Footer />
      </Box>
    </>
  );
};

export default TermsAndConditions;
